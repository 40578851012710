const de_translations = {
  industry_references: 'Branchenbezug',
  company_references: 'Unternehmensbezug',
  intensive_interrogation: 'Intensive Befragung',
  was_the_customer_questioned_intensively: 'Wurde Kunde intensiv befragt, bis eine schlüssige konkrete Darstellung des Ziels möglich war?',
  summary: 'Zusammenfassung',
  were_the_problem_motivation_and_the_desired_goal_summarized: 'Wurden die Problemstellung, Motivation und das angestrebte Ziel während des Austausch mit dem Kunden zusammengefasst und wurde die Zusammenfassung durch den Kunden bestätigt?',
  discussion_with_colleagues: 'Diskussion mit Kollegen',
  was_the_description_discussed_intensively: 'Wurde die Beschreibung "Ziel des Vorhabens" intensiv mit mind. einem Kollegen besprochen und gemeinsam "abgesegnet"',
  motivation: 'Motivation',
  do_i_have_captivating_introduction: 'Habe ich eine "fesselnde" Einleitung: i.e. wird die Motivation plakativ und greifbar geschildert?',
  objectives_envisaged_results: 'Ziele und vorgesehene Ergebnisse',
  are_the_project_goals_recorded: 'Sind die Projektziele möglichst konkret erfasst? Soweit möglich Ziele SMART (spezifisch = konkret, messbar, attraktiv, realistisch, terminiert) formuliert? ',
  structure: 'Struktur',
  can_i_already_recognize_structure_in_the_goal: 'Kann ich im Ziel bereits eine Struktur/Reihenfolge erkennen, die in den anderen Kapiteln aufgegriffen werden kann?',
  are_bad_words_on_target: 'Sind Unworte im Ziel? (Muss umformuliert werden, sonst ergibt ein grünes ja keinen Sinn)',
  have_terms_and_formulations_been_mentioned: 'Sind Begrifflichkeiten und Formulieren analog zu anderen Kapitel genannt worden (WICHTIG: roter Faden)?',
  have_abbreviations_been_introduced_or_are_technical_terms: 'Sind Abkürzungen eingeführt, bzw.Fachtermini verständlich?',
  work_schedule: 'Arbeitsplan',
  is_there_a_work_plan: 'Liegt ein Arbeitsplan vor? Ggf. Musterarbeitsplan zuschicken',
  can_clear_rnd_activities: 'Lassen sich aus dem Arbeitsplan eindeutige FuE-Aktivitäten zum Vorhaben ableiten?',
  can_work_packages_be_divided_into_concept: 'Können Arbeitspakete in Konzept-, Entwicklungs und Testphase unterteilt werden? ',
  security_queries: 'Sicherheitsabfragen',
  is_there_a_structured_description: 'Ist eine strukturierte Beschreibung vorhanden?',
  key_point_structure_in_phases: 'Stichpunktartige Gliederung in Phasen/Arbeitspaketen Achtung! Testphase immer inkl. Rückkopplung d. Ergebnisse in Entwicklung',
  is_the_working_methodology_described: 'Ist die Arbeitsmethodik beschrieben (Sprints, Meilensteine, Parallel, iterativ….)?',
  can_internal_and_external_activities_be_clearly_defined: 'Können interne und externe Tätigkeiten klar abgegrenzt/aufgezeigt werden?',
  have_certain_terms_and_formulations_from_the_goal_been_consistently_incorporated_into_the_work_phases: 'Sind bestimmte Begrifflichkeiten und Formulierungen aus dem Ziel konsistent in den Arbeitspaketen/Phasen eingearbeitet worden?',
  market_research: 'Marktrecherche',
  have_project_delimitations_been_checked_and_compared: 'Sind Vorhabenabgrenzungen mit Produkten/Methoden/Dienstleistungen von Wettbewerbern überprüft und verglichen worden?',
  can_qualitative_and_quantitative_delimitation_features_be_listed: 'Können qualitative und quantitative Abgrenzungsmerkmale aufgeführt werden?',
  has_the_state_of_research_or_development_in_the_industry_been_briefy_described: 'Wurde kurz der Forschungs- bzw. Entwicklungsstand der Branche beschrieben?',
  have_all_new_scientifc_methods_approaches_been_mentioned: 'Wurden alle neuen zum Einsatz kommenden wissenschaftlichen/technischen Methoden bzw. Lösungsansätze oder Vorgehensweisen genannt?',
  has_it_been_checked_whether_there_is_a_clear_demarcation: 'Wurde überprüft, ob eine klare Abgrenzung zum Markt wie Somit ist kein vergleichbares Produkt auf dem Markt vorhanden oder der Firma sind keine vergleichbaren Produkte, ... bekannt vorkommt?',
  have_i_picked_up_the_structure_from_the_goal_here_as_well: 'Habe ich auch hier wieder die Struktur/Reihenfolge aus dem Ziel aufgegriffen?',
  does_the_customer_have_similar_products_or_is_he_already_advertising: 'Hat der Kunde ähnliche Produkte oder bewirbt er das Vorhaben bereits auf der Website?Ggf. Abgrenzung notwendig',
  routine: 'Routine',
  what_are_routine_activities_for_the_company: 'Was sind Routinetätigkeiten für das Unternehmen?',
  how_do_the_new_rnd_activities_difer_from_routine_activities: 'Wie grenzen sich die neuen FuE-Aktivitäten von Routinetätigkeiten ab?',
  what_was_new_to_achieve_the_goal: 'Was wurde alles neu gemacht (Produkt/Verfahren/Methodik/Organisation), um das Ziel zu erreichen?',
  has_it_been_checked_whether_no_routine_occurs: 'Wurde überprüft, ob u.a. keine Routine im Kapitel vorkommt?',
  are_there_any_bad_words_in_the_description: 'Sind Unworte in der Beschreibung? z.B. Weiterentwicklung, weiterentwickeln, optimieren',
  have_terms_and_formulations_been_mentioned_analogous_to_other_chapters: 'Sind Begrifflichkeiten und Formulieren analog zu anderen Kapitel genannt worden (WICHTIG: roter Faden)?',
  risks: 'Risiken',
  which_technical_risks_have_arisen: 'Welche technischen Risiken/Unwägbarkeiten/Herausforderungen sind aufgetreten/könnten auftreten? ',
  has_it_been_carefully_checked_whether_it_is_not_just_hard_work: 'Wurde genau geprüft, ob es sich nicht um reine "Fleißarbeit" handelt?',
  are_risks_quantifiable: 'Sind Risiken quantifizierbar?',
  conficting_goals: 'Zielkonflikte und Abbruchkriterien',
  are_there_technical_conficts_of_interest: 'Gibt es technische Zielkonflikte, die bei Nicht-Erreichen zum Projektabbruch führen?',
  are_the_technical_risks_of_interest_consistent: 'Sind die technischen Risiken/Zielkonflikte konsistent zu den Zielen und den Beschreibungen der Arbeiten?',
  have_i_picked_up_the_structure_sequence_from_the_goal_here_as_well: 'Habe ich auch hier wieder die Struktur/Reihenfolge aus dem Ziel aufgegriffen?',
  has_the_common_consistency_from_the_descriptions_been_checked_with_at_least_one_colleague: 'Wurde die gemeinsame Konsistenz aus den Beschreibungen Ziel des Vorhabens, Beschreibung der Arbeiten, Technische Risiken intensiv mit mind. einem Kollegen überprüft und gemeinsam abgesegnet?',
  natural_sciences: 'Naturwissenschaften',
  engineering_and_technology: 'Ingenieurwissenschaften und Technologie',
  medical_and_health_sciences: 'Medizinische und Gesundheitswissenschaften',
  agricultural_sciences_and_veterinary_medicine: 'Agrarwissenschaften und Veterinärmedizin',
  social_sciences: 'Sozialwissenschaften',
  humanities_and_arts: 'Geisteswissenschaften und Kunst',
  mathematics: 'Mathematik',
  informatics_and_information_sciences: 'Informatik und Informationswissenschaften',
  physics: 'Physik',
  chemistry: 'Chemie',
  geosciences_and_associated_environmental_sciences: 'Geowissenschaften und zugehörige (Umwelt-)Wissenschaften',
  biology: 'Biologie',
  other_natural_sciences: 'Andere Naturwissenschaften',
  civil_engineering: 'Bauingenieurwesen',
  electrical_engineering_electronics_information_technology: 'Elektrotechnik, Elektronik, Informationstechnik',
  mechanical_engineering: 'Maschinenbau',
  chemical_process_engineering: 'Chemische Verfahrenstechnik',
  materials_engineering: 'Werkstofftechnik',
  medical_technology: 'Medizintechnik',
  environmental_engineering: 'Umwelttechnik',
  environmental_biotechnology: 'Umweltbiotechnologie',
  industrial_biotechnology: 'Industrielle Biotechnologie',
  nanotechnology: 'Nanotechnologie',
  other_engineering_sciences_and_technologies: 'Andere Ingenieurwissenschaften und Technologien',
  basic_medicine: 'Grundlagenmedizin',
  clinical_medicine: 'Klinische Medizin',
  health_sciences: 'Gesundheitswissenschaften',
  medical_biotechnology: 'Medizinische Biotechnologie',
  other_medical_sciences: 'Andere medizinische Wissenschaften',
  agriculture_and_forestry: 'Land- und Forstwirtschaft, Fischerei',
  animal_husbandry_and_dairy_and_dairy_science: 'Tierzucht sowie Milch- und Molkereiwissenschaft',
  veterinary_medicine: 'Veterinärmedizin',
  agricultural_biotechnology: 'Agrarbiotechnologie',
  other_agricultural_sciences: 'Andere Agrarwissenschaften',
  psychology_and_cognitive_science: 'Psychologie und Kognitionswissenschaft',
  economics_and_business_administration: 'Volks- und Betriebswirtschaftslehre',
  educational_sciences: 'Erziehungswissenschaften',
  sociology: 'Soziologie',
  jurisprudence: 'Rechtswissenschaften',
  political_science: 'Politikwissenschaft',
  social_and_economic_geography: 'Sozial- und Wirtschaftsgeografie',
  media_and_communication_studies: 'Medien- und Kommunikationswissenschaften',
  other_social_sciences: 'Andere Sozialwissenschaften',
  history_and_archeology: 'Geschichte und Archäologie',
  languages_and_literature: 'Sprachen und Literatur',
  philosophy_ethics_religion: ' Philosophie, Ethik und Religion',
  art_visual_arts_art_history_performing_arts_music: 'Kunst (bildende Kunst, Kunstgeschichte, darstellende Kunst, Musik)',
  other_humanities: 'Andere Geisteswissenschaften',
  subcategory_related_to_the_selected_branch_of_research: 'Unterkategorie Forschungszweig',
  contact_person: 'Ansprechpartner',
  collaborative_research_with_at_least_one_other_company: 'Kooperation mit mindestens einem anderen Unternehmen und/oder einer Forschungseinrichtung?',
  branch_of_research_related_to_rnd_project: 'FuE-Forschungszweig',
  this_document_is_the_property_of_ayming: 'Dieses Dokument ist Eigentum von Ayming',
  min_3_keywords: 'Mindestens 3 Schlagwörter',
  which_work_steps_are_used: 'Welche Arbeitsschritte/Aufgaben und Methoden werden/wurden zur Erreichung des Ziels angewendet? Inwieweit werden dabei (für Ihr Unternehmen und/oder den Wirtschaftszweig) neuartige Ansätze verfolgt?',
  is_the_project_aimed_at_a_product: 'Zielt das Vorhaben auf ein Produkt, Produktionsverfahren, eine Produktionslinie, eine Dienstleistung oder eine wissenschaftliche Methodik ab, das/die eine deutliche Weiterentwicklung/Neuheit in Bezug auf den betreffenden Wirtschaftszweig darstellt?',
  project_have_a_specific_connection: 'Besteht ein konkreter Bezug des Vorhabens zu bestehenden Produkten, Produktionslinien, Produktionsverfahren, Dienstleistungen oder bereits etablierter wissenschaftlicher Methodik in Ihrem Unternehmen?',
  explain_which_engineering: 'Erläutern Sie, welche ingenieurs-, natur- geistes-, oder sozialwissenschaftlichen Hemmnisse für den Lösungsansatz Ihres Vorhabens identifiziert werden können. Beschreiben Sie die wissenschaftlichen (bezogen auf alle Wissenschaftszweige) und/oder die technischen Hemmnisse, Risiken, Unwägbarkeiten oder Herausforderungen, die zum Scheitern des Vorhabens führen könnten. Beschreiben Sie, wie Sie mit vorliegenden Risiken umgehen. Erläutern Sie kurz Meilensteine und Abbruchkriterien. Wirtschaftlich und/oder finanzielle Risiken sind nicht zu benennen!',
  financial_and_personnel_framework: 'Finanzieller und Personeller Rahmen',
  were_the_personnel_expenses_funded_within_the_framework_of_other_funding: 'Wurden/Werden die Personalaufwendungen und/oder Auftragskosten für Aufträge innerhalb der EU/des EWR für dieses FuE-Vorhaben im Rahmen anderer Förderungen oder staatlicher Beihilfen gefördert?',
  research_allowance_project_profile: 'Forschungszulage: Vorhabensteckbrief',
  project_profile: 'Vorhabensteckbrief',
  key_data: 'Eckdaten',
  from: 'Von',
  to: 'Bis',
  all_works_involved: 'Beschreibung der Arbeiten',
  contact_person_customer: 'Ansprechpartner Kunde',
  role: 'Funktion',
  phone_number: 'Telefon',
  mobile_number: 'Mobile',
  department: 'Abteilung',
  ayming_contact_person: 'Ansprechpartner Ayming',
  type_of_rnd: 'Tätigkeitsform',
  word_yes: 'Ja',
  word_no: 'Nein',
  yes: 'Ja',
  no: 'Nein',
  complete_in_house_research: 'Vollständige eigenbetriebliche Forschung?',
  contract_research: 'Auftragsforschung ?',
  personnel_framework_must_be_specified: 'Der personelle Rahmen muss für jedes (angefangene) Kalenderjahr, dass das Vorhaben umfasst, angegeben werden',
  one_person_month: '1 Personenmonat (PM) = 20 Personentage',
  rnd_stakeholders: 'FuE-Beteiligte',
  departments_involved: 'Beteiligte Abteilungen',
  number_of_employees: 'Anzahl der Mitarbeitenden',
  are_all_employees_employed_by_the_named_company: 'Sind alle Mitarbeitenden beim Antragsteller angestellt ?',
  internal_project_numbers: 'Interne Projektnummern',
  number: 'Anzahl',
  name: 'Name',
  notes: 'Bemerkung',
  content_and_technical_information: 'Inhaltliche Angaben',
  project_name: 'Projektname',
  keywording: 'Schlagworte',
  company_institute_name: 'Name Auftragnehmer',
  contracting_partners_type: 'Art',
  legal_form: 'Rechtsform',
  address: 'Adresse',
  street: 'Straße',
  house_number: 'Hausnummer',
  additional_house_number_details: 'Hausnummerzusatz',
  city: 'Ort',
  post_code: 'PLZ',
  additional_address_details: 'Adressergänzung',
  country: 'Land',
  eu_eea: 'EU/EWREU/EEA',
  description_of_work: 'Beschreibung aller Arbeiten',
  estimated: 'Schätzung',
  invoice: 'Rechnung',
  offer: 'Angebot',
  comments: 'Kommentar',
  collaborative_research: 'Kooperationsforschung',
  company_university_research_institute_name: 'Name Kooperationspartner',
  financial_framework_in_euro: 'Finanzieller Rahmen [in EUR]',
  financial_framework_of_the_project_total: 'Finanzieller Rahmen des Vorhabens (gesamt)',
  rnd_personnel_costs: 'davon FuE-Personalkosten',
  rnd_material_costs: 'davon FuE-Sachkosten und Kosten für Investitionen (nicht förderfähig, nur grob abschätzen)',
  rnd_contract_costs_within_eu: 'davon FuE-Auftragskosten für Auftragnehmer innerhalb EU/EWR',
  rnd_contract_costs_not_in_eu: 'davon FuE-Auftragskosten für Auftragnehmer außerhalb EU/EWRR',
  rnd_other_costs: 'davon sonstige FuE-Kosten (nicht förderfähig, nur grob abschätzen)',
  personnel_framework_in_pm: 'Personeller Rahmen [in PM]',
  personnel_framework_of_the_project_total: 'Personeller Rahmen des Vorhabens (gesamt)',
  personnel_framework_of_the_project_scientists: 'davon Akademiker',
  personnel_framework_of_the_project_technicians: 'davon Techniker',
  personnel_framework_of_the_project_others: 'davon sonstige',
  additional_information: 'Zusätzliche Informationen:',
  staff_cost_per_day: 'Stunden pro Tag',
  staff_cost_per_hour: 'Stundensatz',
  first_fast_check: 'Erster Schnellcheck',
  date_of_the_kickoff: 'Kick-off Datum',
  should_the_project_advance_date: 'Go/No-Go Entscheidung Antragstellung  - Datum',
  criteria: 'Kriterien',
  rating_estimation: 'Bewertung/Einschätzung',
  rough_cost_calculation: 'Grobe Kostenabschätzung',
  novel: 'Neuartig',
  transferable: 'Übertragbar',
  creative: 'Schöpferisch',
  systematic: 'Systematisch',
  uncertain: 'Ungewiss',
  projects_duration: 'Projektlaufzeit',
  fulfilled: 'erfüllt',
  partially_fulfilled: 'bedingt erfüllt',
  not_fulfilled: 'nicht erfüllt',
  last_changes: 'Letzte Änderung',
  page: 'Seite',
  additional_demand: 'Nachforderung',
  additional_claim_info: 'Angaben zur Nachforderung',
  received_on: 'Eingegangen am',
  period_for_reply: 'Frist zur Beantwortung',
  project_id: 'Vorhabens-ID',
  original_text: 'Originaltext der BSFZ-Nachforderung',
  research_allowance_additional_requirement: 'Forschungszulage: Nachforderung',
  answering_questions: 'Beantwortung der Fragen',
  please_answer_the_questions_in_the_following_table: 'Bitte beantworten Sie die Fragen in der folgenden Tabelle formlos.',
  queries: 'Rückfrage',
  answers: 'Antwort',
  germany: 'Deutschland',
  afghanistan: 'Afghanistan',
  albania: 'Albanien',
  algeria: 'Algerien',
  andorra: 'Andorra',
  angola: 'Angola',
  antigua: 'Antigua & Deps',
  argentina: 'Argentinien',
  armenia: 'Armenien',
  australia: 'Australien',
  austria: 'Österreich',
  azerbeijan: 'Aserbaidschan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesch',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgien',
  belize: 'Belize',
  benin: 'Benin',
  bhutan: 'Bhutan',
  bolivia: 'Bolivien',
  bosnia: 'Bosnien-herzegowina',
  botsuana: 'Botsuana',
  brazil: 'Brazilien',
  brunei: 'Brunei',
  bulgaria: 'Bulgarien',
  burkina: 'Burkina',
  burundi: 'Burundi',
  cambogia: 'Kambodscha',
  camerun: 'Kamerun',
  canada: 'Kanada',
  capo_verde: 'Kap Verde',
  central_african_rep: 'Zentralafrikanische Vertretung',
  chad: 'Tschad',
  chile: 'Chile',
  china: 'China',
  columbia: 'Kolumbien',
  cameroon: 'Komoren',
  congo: 'Kongo',
  congo_democratic: 'Kongo Demokratische Republik',
  costa_rica: 'Costa Rica',
  croatia: 'Kroatien',
  cuba: 'Kuba',
  cyprus: 'Zypern',
  czech_republic: 'Tschechische Republik',
  denmark: 'Dänemark',
  djibouti: 'Dschibuti',
  dominica: 'Dominica',
  dominican_republic: 'Dominikanische Republik',
  east_timor: 'Osttimor',
  ecuador: 'Ecuador',
  egypt: 'Ägypten',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Äquatorialguinea',
  eritrea: 'Eritrea',
  estonia: 'Estland',
  ethiopia: 'Äthiopien',
  fiji: 'Fidschi',
  finland: 'Finnland',
  france: 'Frankreich',
  gabon: 'Gabun',
  gambia: 'Gambia',
  georgia: 'Georgien',
  ghana: 'Ghana',
  greece: 'Griechenland',
  grenada: 'Grenada',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hungary: 'Ungarn',
  iceland: 'Island',
  india: 'Indien',
  indonesia: 'Indonesien',
  iran: 'Iran',
  irak: 'Irak',
  ireland: 'Irland Republik',
  israel: 'Israel',
  italy: 'Italien',
  ivory_coast: 'Elfenbeinküste',
  jamaica: 'Jamaika',
  japan: 'Japan',
  jordan: 'Jordanien',
  kazachstan: 'Kasachstan',
  kenia: 'Kenia',
  kiribati: 'Kiribati',
  north_korea: 'Korea Nord',
  south_korea: 'Südkorea Süd',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  kirgyzstan: 'Kirgistan',
  laos: 'Laos',
  latvia: 'Lettland',
  lebanon: 'Libanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libiya: 'Libyen',
  liechenstein: 'Liechtenstein',
  lithuania: 'Litauen',
  luxembourg: 'Luxemburg',
  macedonia: 'Mazedonien',
  madagaskar: 'Madagaskar',
  malawi: 'Malawi',
  malaysia: 'Malaysien',
  maldives: 'Malediven',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshallinseln',
  mauritania: 'Mauretanien',
  mauritius: 'Mauritius',
  mexico: 'Mexiko',
  micronesia: 'Mikronesien',
  moldova: 'Republik Moldau',
  monaco: 'Monaco',
  mongolia: 'Mongolei',
  montenegro: 'Montenegro',
  marocco: 'Marokko',
  mozambique: 'Mosambik',
  myanmar: 'Myanmar, Birma',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Niederlande',
  new_zealand: 'Neuseeland',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  norwegia: 'Norwegen',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papua_new_guinea: 'Papua-Neuguinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippinen',
  poland: 'Polen',
  portugal: 'Portugal',
  qatar: 'Katar',
  romania: 'Rumänien',
  russian_federation: 'Russische Föderation',
  rwanda: 'Ruanda',
  st_kitts_nevis: 'St. Kitts und Nevis',
  st_lucia: 'St. Lucia',
  st_vincent: 'St. Vincent und die Grenadinen',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome: 'São Tomé & Príncipe',
  saudi_arabia: 'Saudi-Arabien',
  senegal: 'Senegal',
  serbia: 'Serbien',
  seychelles: 'Seychellen',
  sierra_leone: 'Sierra Leone',
  singapoore: 'Singapur',
  slovakia: 'Slowakei',
  slovenia: 'Slowenien',
  solomon_islands: 'Salomonen',
  south_africa: 'Südafrika',
  south_sudan: 'Südsudan',
  spain: 'Spanien',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swasiland',
  sweeden: 'Schweden',
  switzerland: 'Schweiz',
  syria: 'Syrien',
  taiwan: 'Taiwan',
  tajikistan: 'Tadschikistan',
  tanzania: 'Tansania',
  thailand: 'Thailand',
  togo: 'Togo',
  Tonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad und Tobago',
  tunis: 'Tunesien',
  turkey: 'Turkei',
  turkmenistan: 'Turkmenistan',
  tuavalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'Vereinigte Arabische Emirate',
  united_kingdom: 'Vereinigtes Königreich',
  united_states: 'USA',
  uruguay: 'Uruguay',
  uzbekistan: 'Usbekistan',
  vanuatu: 'Vanuatu',
  vatican_city: 'Vatikanstadt',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  yemen: 'Jemen',
  sambia: 'Sambia',
  zimbabwe: 'Zimbabwe',
  baden_wuerttemberg: 'Baden-Wurttemberg',
  bayern: 'Bayern',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamuburg: 'Hamburg',
  hesse: 'Hessen',
  mecklenburg_vorpommern: 'Mecklenburg-Vorpommern',
  lower_saxony: 'Niedersachsen',
  north_rhine_westphalia: 'Nordrhein-Westfalen',
  rheinland_pfalz: 'Rheinland-Pfalz',
  saarland: 'Saarland',
  saxony: 'Sachsen',
  saxony_anhalt: 'Sachsen-Anhalt',
  schleswig_holstein: 'Schleswig-Holstein',
  thuringia: 'Thuringen',

  //elsterdocx
  transmission_protocol: 'Übertragungsprotokoll',
  general_information_about_the_company_1: '1. Allgemeine Angaben zum Unternehmen',
  application_for_research_allowance: 'Antrag auf Forschungszulage',
  application_for_research_allowance_body: 'Bitte beachten Sie, dass Sie für jedes Wirtschaftsjahr nur einen Antrag auf Forschungszulage stellen können. Das gilt auch, wenn Sie mehrere begünstigte Forschungs- und Entwicklungsvorhaben in einem Wirtschaftsjahr durchgeführt haben.',
  business_address: 'Geschäftsanschrift',
  address_at_home_abroad: 'Geschäftsanschrift',
  legal_representative: 'Gesetzlicher Vertreter',
  information_on_tax_exemption: 'Angaben zur Steuerbefreiung',
  information_on_the_distribution: 'Angaben zur Verteilung der maximal förderfähigen Bemessungsgrundlage bei verbundenen Unternehmen',
  information_on_affiliated_companies: 'Angaben zu verbundenen Unternehmen ',
  gci_please_note_that_only: 'Bitte beachten Sie, dass nur die verbundenen Unternehmen einzutragen sind, die ebenfalls einen Antrag auf Forschungszulage gestellt haben oder stellen werden.',
  information_on_total_wage_cost_in_the_enterprise_during_the_financial_year: 'Angaben zu Lohnaufwendungen insgesamt im Unternehmen im Wirtschaftsjahr ',
  information_on_wage_costs_of_employees_engaged_in_research: 'Angaben zu Lohnaufwendungen der in Forschungs- und Entwicklungsvorhaben tätigen Arbeitnehmer insgesamt im Unternehmen im Wirtschaftsjahr ',
  additional_information_for_partnership: 'Additional information for partnerships',
  additional_information_for_partnership_subtitle: 'Empfangsbevollmächtigter nach §122 Absatz 6 der Abgabenordnung für den Bescheid über die gesonderte und einheitliche Feststellung von Anteilen an der festgesetzten Forschungszulage',
  details_of_the_parties_involved_in_the_assessment: 'Angaben zu den Feststellungsbeteiligten am Ende des Wirtschaftsjahres',
  information_on_breakdown_11: 'Angaben zur Aufteilung der Forschungszulage nach gezeichnetem oder eingezahltem Kapital oder nach Bruchteilen',

  for_the_financial_year: 'Für das Wirtschaftsjahr',
  tax_reference: 'Steuernummer',
  company_name: 'Name/Bezeichnung des Unternehmens',
  location: 'Sitz (Gemeinde)',
  industry: 'Wirtschaftszweig',
  tax_group: 'Organschaft',
  postal_code: 'Postleitzahl',
  place: 'Ort',
  country_in_case_of_foreign_countries: 'Staat (im Falle Ausland)',
  salutation: 'Anrede',
  forename: 'Vorname',
  gci_4_the_company_exempt: 'Das Unternehmen ist nach § 5 des KStG von der Besteuerung befreit.',
  gci_4_the_research_and_dev: 'Die nachfolgend erklärten Forschungs- und Entwicklungsvorhaben wurden im Rahmen eines wirtschaftlichen Geschäftsbetriebs oder eines nicht steuerbefreiten Betriebs gewerblicher Art von juristischen Personen des öffentlichen Rechts durchgeführt.',
  gci_5_it_is_an_affiliated: 'Es handelt sich um ein verbundenes Unternehmen im Sinne von § 3 Absatz 6 FZulG. Maßgeblich sind die Verhältnisse am Ende des Wirtschaftsjahres.',
  gci_5_has_a_company_affiliated: 'Hat ein mit Ihnen verbundenes Unternehmen für Wirtschaftsjahre, die im gleichen Kalenderjahr enden in dem Ihr Wirtschaftsjahr endet, Forschungszulage beantragt beziehungsweise wird ein mit Ihnen verbundenes Unternehmen Forschungszulage beantragen?',
  gci_5_will_the_max_eligible_ex: 'Wird die maximal förderfähige Bemessungsgrundlage für verbundene Unternehmen in Höhe von 4 Millionen € überstiegen? ',
  gci_5_maximum_eligible_ass: 'Maximale förderfähige Bemessungsgrundlage, die für diesen Antrag auf Forschungszulage berücksichtigt werden soll.',
  gci_6_tax_number: 'Steuernummer des verbundenen Unternehmens',
  gci_6_name_of_the_aff: 'Name des verbundenen Unternehmens',
  gci_6_if_available: 'Soweit vorhanden:  Datum des Antrags auf Forschungszulage Ggf. Datum des Bescheids auf Forschungszulage',
  gci_7_total_number_of: 'Anzahl der im Unternehmen beschäftigten Arbeitnehmer, für die ein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_7_number_of_employees: 'Anzahl der im Unternehmen beschäftigten Arbeitnehmer, für die aufgrund einer DBA-Regelung kein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_7_gross_wages_granted_deduction: 'Bruttoarbeitslöhne, die der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und von dem der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG vorgenommen wurde, insgesamt im Wirtschaftsjahr',
  gci_7_gross_wages_granted_pursuant: 'Bruttoarbeitslöhne, die der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und für die der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG aufgrund einer DBA-Regelung nicht vorgenommen wird, insgesamt im Wirtschaftsjahr',
  gci_7_expenditure: 'Ausgaben für die Zukunftssicherung der Arbeitnehmer nach § 3 Nummer 62 EStG insgesamt im Wirtschaftsjahr ',
  gci_8_total_number: 'Anzahl der im Unternehmen mit Forschungs- und Entwicklungsvorhaben beschäftigten Arbeitnehmer, für die ein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_8_number_of_employees: 'Anzahl der im Unternehmen mit Forschungs- und Entwicklungsvorhaben beschäftigten Arbeitnehmer, für die aufgrund einer DBA-Regelung kein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_8_gross_wages_deducted: 'Bruttoarbeitslöhne, die der Arbeitgeber den mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmern unmittelbar gewährt und von denen der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG vorgenommen wurde, insgesamt im Wirtschaftsjah',
  gci_8_gross_wages_pursuant: 'Bruttoarbeitslöhne, die der Arbeitgeber den mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmern unmittelbar gewährt und für die der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG aufgrund einer DBA-Regelung nicht vorgenommen wurde, insgesamt im Wirtschaftsjahr',
  gci_8_expenditure: 'Ausgaben für die Zukunftssicherung der mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmer nach § 3 Nummer 62 EStG insgesamt im Wirtschaftsjahr',
  name_line_1: 'Namenszeile 1',
  name_line_2: 'Namenszeile 2',
  gci_10_number: 'Nummer des Feststellungsbeteiligten',
  tin: 'Steuernummer',
  identificaiton: 'Identifikationsnummer',
  type_of_participant: 'Art des Beteiligten',
  gci_11_capital: 'Kapital am Ende des Wirtschaftsjahres',
}

export default de_translations
