const en_translations = {
  project_objectives: 'Projects Objectives',
  company_references: 'Company References',
  intensive_interrogation: 'Intensive Interrogation',
  was_the_customer_questioned_intensively: 'Was the customer questioned intensively until a coherent, concrete description of the goal was possible?',
  summary: 'Summary',
  were_the_problem_motivation_and_the_desired_goal_summarized: 'Were the problem, motivation and the desired goal summarized during the exchange with the customer and was the summary confirmed by the customer?',
  discussion_with_colleagues: 'Discussion with Colleagues',
  was_the_description_discussed_intensively: 'Was the description "Objective of the project" discussed intensively with at least one colleague and "approved" together?',
  motivation: 'Motivation',
  do_i_have_captivating_introduction: 'Do I have a "captivating" introduction: i.e. is the motivation described in a striking and tangible way?',
  objectives_envisaged_results: 'Objectives & Envisaged results',
  are_the_project_goals_recorded: 'Are the project goals recorded as specifcally as possible? As far as possible',
  structure: 'Structure',
  can_i_already_recognize_structure_in_the_goal: 'Can I already recognize a structure/sequence in the goal that can be taken up in the other chapters?',
  security_queries: 'Security Queries',
  are_bad_words_on_target: 'Are bad words on target? (Must be rephrased, otherwise a green doesnt make sense)',
  have_terms_and_formulations_been_mentioned: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_abbreviations_been_introduced_or_are_technical_terms: 'Have abbreviations been introduced or are technical terms understandable?',
  work_schedule: 'Work Schedule',
  is_there_a_work_plan: 'Is there a work plan, If necessary, send sample work plan.',
  can_clear_rnd_activities: 'Can clear R&D activities for the project be derived from the work plan?',
  can_work_packages_be_divided_into_concept: 'Can work packages be divided into concept, development and test phases?',
  is_there_a_structured_description: 'Is there a structured description?',
  key_point_structure_in_phases: 'Key-point structure in phases/work packages Danger! Test phase always including feedback d. results in development',
  is_the_working_methodology_described: 'Is the working methodology described (sprints, milestones, parallel, iterative...)?',
  can_internal_and_external_activities_be_clearly_defined: 'Can internal and external activities be clearly defined/delineated?',
  have_certain_terms_and_formulations_from_the_goal_been_consistently_incorporated_into_the_work_phases: 'Have certain terms and formulations from the goal been consistently incorporated into the work packages/phases?',
  market_research: 'Market Research',
  have_project_delimitations_been_checked_and_compared: 'Have project delimitations been checked and compared with products/methods/services from competitors?',
  can_qualitative_and_quantitative_delimitation_features_be_listed: 'Can qualitative and quantitative delimitation features be listed?',
  has_the_state_of_research_or_development_in_the_industry_been_briefy_described: 'Has the state of research or development in the industry been briefly described?',
  have_all_new_scientifc_methods_approaches_been_mentioned: 'Have all new scientific/technical methods, approaches or approaches to be used been mentioned?',
  has_it_been_checked_whether_there_is_a_clear_demarcation: 'Has it been checked whether there is a clear demarcation from the market such as therefore there is no comparable product on the market or the company is not aware of any comparable products?',
  have_i_picked_up_the_structure_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  does_the_customer_have_similar_products_or_is_he_already_advertising: 'Does the customer have similar products or is he already advertising the project on the website? Possibly demarcation necessary',
  routine: 'Routine',
  what_are_routine_activities_for_the_company: 'What are routine activities for the company?',
  how_do_the_new_rnd_activities_difer_from_routine_activities: 'How do the new R&D activities differ from routine activities?',
  what_was_new_to_achieve_the_goal: 'What was new (product/procedure/method/organization) to achieve the goal?',
  has_it_been_checked_whether_no_routine_occurs: 'Has it been checked whether, among other things, no routine occurs in the chapter?',
  are_there_any_bad_words_in_the_description: 'Are there any bad words in the description? e.g. further development, further develop, optimize',
  risks: 'Risks',
  which_technical_risks_have_arisen: 'Which technical risks/uncertainties/challenges have arisen/could arise?',
  has_it_been_carefully_checked_whether_it_is_not_just_hard_work: 'Has it been carefully checked whether it is not just "hard work"?',
  are_risks_quantifiable: 'Are risks quantifiable?',
  conficting_goals: 'Conflicting Goals & Termination Criteria',
  are_there_technical_conficts_of_interest: 'Are there technical conflicts of interest that lead to the project being canceled if they are not achieved?',
  are_the_technical_risks_of_interest_consistent: 'Are the technical risks/conflicts of interest consistent with the objectives and the descriptions of the work?',
  have_terms_and_formulations_been_mentioned_analogous_to_other_chapters: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_i_picked_up_the_structure_sequence_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  has_the_common_consistency_from_the_descriptions_been_checked_with_at_least_one_colleague: 'Has the common consistency from the descriptions "Objective of the project", "Description of the work", "Technical risks been checked intensively with at least one colleague and approved together?',
  germany: 'Germany',
  afganistan: 'Afghanistan',
  albania: 'Albania',
  algeria: 'Algeria',
  andorra: 'Andorra',
  angolla: 'Angola',
  antigua: 'Antigua & Deps',
  argentina: 'Argentina',
  armenia: 'Armenia',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgium',
  belize: 'Belize',
  benin: 'Benin',
  bhutan: 'Bhutan',
  bolivia: 'Bolivia',
  bosnia: 'Bosnia Herzegovina',
  botswana: 'Botswana',
  brazil: 'Brazil',
  brunei: 'Brunei',
  bulgaria: 'Bulgaria',
  burkina: 'Burkina',
  burundi: 'Burundi',
  cambodia: 'Cambodia',
  cameroon: 'Cameroon',
  canada: 'Canada',
  cape_verde: 'Cape Verde',
  central_african_rep: 'Central African Rep',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  colombia: 'Colombia',
  comoros: 'Comoros',
  congo: 'Congo',
  congo_democratic: 'Congo {Democratic Rep}',
  cost_rica: 'Costa Rica',
  croatia: 'Croatia',
  cuba: 'Cuba',
  cyprus: 'Cyprus',
  czech_republic: 'Czech Republic',
  denmark: 'Denmark',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  dominican_republic: 'Dominican Republic',
  east_timor: 'East Timor',
  ecuador: 'Ecuador',
  egypt: 'Egypt',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Equatorial Guinea',
  eritrea: 'Eritrea',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  fiji: 'Fiji',
  finland: 'Finland',
  france: 'France',
  gabon: 'Gabon',
  gambia: 'Gambia',
  georgia: 'Georgia',
  ghana: 'Ghana',
  greece: 'Greece',
  grenada: 'Grenada',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hungary: 'Hungary',
  iceland: 'Iceland',
  india: 'India',
  indonesia: 'Indonesia',
  iran: 'Iran',
  iraq: 'Iraq',
  ireland: 'Ireland {Republic}',
  israel: 'Israel',
  italy: 'Italy',
  ivory_coast: 'Ivory Coast',
  jamaica: 'Jamaica',
  japan: 'Japan',
  jordan: 'Jordan',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kiribiati: 'Kiribati',
  north_korea: 'Korea North',
  south_korea: 'Korea South',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  kyrgyzstan: 'Kyrgyzstan',
  laos: 'Laos',
  latvia: 'Latvia',
  lebanon: 'Lebanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libya: 'Libya',
  liechenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  maldives: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshall Islands',
  mauritania: 'Mauritania',
  mauritius: 'Mauritius',
  mexico: 'Mexico',
  micronesia: 'Micronesia',
  moldova: 'Moldova',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  morocco: 'Morocco',
  mozambique: 'Mozambique',
  myanmar: 'Myanmar, {Burma}',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Netherlands',
  new_zealand: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  norway: 'Norway',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papua_new_guinea: 'Papua New Guinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippines',
  poland: 'Poland',
  portugal: 'Portugal',
  qatar: 'Qatar',
  romania: 'Romania',
  russian_federation: 'Russian Federation',
  rwanda: 'Rwanda',
  st_kitts_nevis: 'St Kitts & Nevis',
  st_lucia: 'St Lucia',
  saint_vincent: 'Saint Vincent & the Grenadines',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome: 'Sao Tome & Principe',
  saudi_arabia: 'Saudi Arabia',
  senegal: 'Senegal',
  serbia: 'Serbia',
  seychelles: 'Seychelles',
  sierra_leone: 'Sierra Leone',
  singapore: 'Singapore',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  solomon_islands: 'Solomon Islands',
  somalia: 'Somalia',
  south_africa: 'South Africa',
  south_sudan: 'South Sudan',
  spain: 'Spain',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swaziland',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  syria: 'Syria',
  taiwan: 'Taiwan',
  tajikistan: 'Tajikistan',
  tanzania: 'Tanzania',
  thailand: 'Thailand',
  togo: 'Togo',
  thonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad & Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turkey',
  turkmenistan: 'Turkmenistan',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'United Arab Emirates',
  united_kingdom: 'United Kingdom',
  united_states: 'United States',
  uruguay: 'Uruguay',
  uzbekistan: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  vatican_city: 'Vatican City',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  yemen: 'Yemen',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
  baden_wuerttemberg: 'Baden-Wurttemberg',
  bayern: 'Bavaria',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamuburg: 'Hamburg',
  hesse: 'Hesse',
  mecklenburg_vorpommern: 'Mecklenburg Western Pomerania',
  lower_saxony: 'Lower Saxony',
  north_rhine_westphalia: 'Northrhine-Westphalia',
  rheinland_pfalz: 'Rhineland Palatinate',
  saarland: 'Saarland',
  saxony: 'Saxony',
  saxony_anhalt: 'Saxony-Anhalt',
  schleswig_holstein: 'Schleswig Holstein',
  thuringia: 'Thuringia',

  //elsterdocx
  transmission_protocol: 'Transmission Protocol',
  general_information_about_the_company_1: '1. General information about the company',
  application_for_research_allowance: 'Application for research allowance',
  application_for_research_allowance_body: 'Please note that you can only apply for a research allowance for each financial year. This also applies if you have carried out several eligible research and development projects in one financial year.',
  business_address: 'Business Address',
  address_at_home_abroad: 'Address at Home/Abroad',
  legal_representative: 'Legal Representative',
  information_on_tax_exemption: 'Information on tax exemption',
  information_on_the_distribution: 'Information on the distribution of the maximum eligible tax base for affiliated companies',
  information_on_affiliated_companies: 'Information on affiliated companies',
  gci_please_note_that_only: 'Please note that only affiliated companies that have also submitted or will apply for a research allowance are to be registered.',
  information_on_total_wage_cost_in_the_enterprise_during_the_financial_year: 'Information on total wage costs in the enterprise during the financial year',
  information_on_wage_costs_of_employees_engaged_in_research: 'Information on wage costs of employees engaged in research and development projects in the enterprise as a whole in the financial',
  additional_information_for_partnership: 'Additional information for partnerships',
  additional_information_for_partnership_subtitle: 'Authorised recipient pursuant to Section 122 (6) of the German Fiscal Code for the decision on the separate and uniform determination of shares in the determined research allowance',
  details_of_the_parties_involved_in_the_assessment: 'Details of the parties involved in the assessment at the end of the financial year',
  information_on_breakdown_11: 'Information on the breakdown of the research allowance by subscribed or paid-in capital or by fractions',


  research_allowance_project_profile: 'Research Allowance: Transfer Protocol',
  company_name: 'Research Allowance: Transfer Protocol',
  location: 'Location',
  legal_form: 'Legal Form',
  industry: 'Industry',
  tax_group: 'Tax Group',
  street: 'street',
  house_number: 'House Number',
  postal_code: 'postal code',
  place: 'Place',
  country_in_case_of_foreign_countries: 'Country in Case of Foreign Countries',
  salutation: 'Salutation',
  name: 'Name',
  forename: 'Forename',
  gci_4_the_company_exempt: 'The company is exempt from taxation under Section 5 of the Corporate Income Tax Act',
  gci_4_the_research_and_dev: 'The research and development projects described below were carried out within the framework of an economic business operation or a non-tax-exempt business of a commercial nature by legal entities under public law',
  gci_5_it_is_an_affiliated: 'It is an affiliated company within the meaning of Section 3 (6) FZulG. The circumstances at the end of the financial year are decisive',
  gci_5_has_a_company_affiliated: 'Has a company affiliated with you applied for a research allowance for financial years that end in the same calendar year in which your financial year ends, or will an affiliated company apply for a research allowance?',
  gci_5_will_the_max_eligible_ex: 'Will the maximum eligible assessment basis for affiliated companies of €4 million be exceeded?',
  gci_5_maximum_eligible_ass: 'Maximum eligible assessment basis to be taken into account for this application for research allowance.',
  gci_6_tax_number: 'Tax number of the affiliated company',
  gci_6_name_of_the_aff: 'Name of the affiliated company',
  gci_6_if_available: 'If available: Date of application for research allowance If applicable, date of the decision on the research allowance',
  gci_7_total_number_of: 'Total number of employees employed by the company for whom payroll tax is deducted in the financial year (in terms of full-time equivalents)',
  gci_7_number_of_employees: 'Number of employees employed by the company for whom no wage tax is deducted due to a DTA regulation, in total in the financial year (according to full-time equivalents)',
  gci_7_gross_wages_granted_deduction: 'Gross wages granted directly by the employer to employees and from which the wage tax deduction was made in accordance with Section 38 (1) of the Income Tax Act in total in the financial year',
  gci_7_gross_wages_granted_pursuant: 'Gross wages granted directly by the employer to employees and for which wage tax deduction pursuant to Section 38 (1) EStG is not made due to a DTA regulation, in total in the financial year',
  gci_7_expenditure: 'Expenditure for securing the future of employees in accordance with § 3 no. 62 EStG in total in the financial',
  gci_8_total_number: 'Total number of employees employed in the enterprise engaged in research and development projects for whom income tax is deducted in the financial year (by full-time equivalents)',
  gci_8_number_of_employees: 'Number of employees employed in the company with research and development projects for whom no wage tax is deducted due to a DTA regulation, in total in the financial year (according to full-time equivalents)',
  gci_8_gross_wages_deducted: 'Gross wages granted directly by the employer to employees entrusted with research and development projects and from which wage tax was deducted in accordance with Section 38 (1) of the Income Tax Act in total in the financial year',
  gci_8_gross_wages_pursuant: 'Gross wages granted directly by the employer to employees entrusted with research and development projects and for which wage tax deduction pursuant to Section 38 (1) of the Income Tax Act has not been made due to a DTA regulation, in total in the financial year',
  gci_8_expenditure: 'Expenditure for securing the future of employees entrusted with research and development projects in accordance with Section 3 No. 62 of the Income Tax Act in the financial year as a whole',
  name_line_1: 'Name line 1',
  name_line_2: 'Name line 2',
  gci_10_number: 'Number of the party involved in the determination',
  postcode: 'Postal code',
  tin: 'Tin',
  identificaiton: 'Identificaiton',
  type_of_participant: 'Type of Participant',
  gci_11_capital: 'Capital at the end of the financial year',
  this_document_is_the_property_of_ayming: 'This Document is property of Ayming',
}

export default en_translations
